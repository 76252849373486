import React, { useState, useEffect,useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CountryDropdown from "../../layouts/countryDropdown/CountryDropdown";
import FormLabel from "@mui/material/FormLabel";
import { useDebounce } from 'usehooks-ts'
import { getCampaignsData } from "../../apis/campaigns/campaigns";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import {
  useGetPrescribersQuery,
  useAddContactQuery,
} from "../../utils/ApiContacts";
import DropdownInupt from "../../components/shared/Dropdown";
// import ReactAutocomplete from "react-autocomplete";
import SearchInput from "../../layouts/searchInput/SearchInput";
import config from "../../env.json";
// import ReactAutocomplete from "react-autocomplete";
import AssociatedPresList from "../../layouts/AssociatedPrescriber/AssociatedPresList";
import {setShowSuccessSnackBar, setShowErrorSnackBar,setCustomErrorSnackBar, setCustomError } from '../../app/slice/personalisationSlice';
import axios from "axios";
import {getFormatedPhoneNumber} from '../../utils/phoneNumberUtil';
import "./NewChatMobile.css";
import {sendMessageApi} from '../../apis/chat/messageApi'
import GenericButton from "../../layouts/button/Button";
import { parsePhoneNumber } from "react-phone-number-input";
import {addNewContact,checkNumberExist, editContact} from '../../apis/contacts/contactsApi'
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import { setChatDetails,setRefreshChatList,setChatType,setChatShowChat, setCustomChatErrorSnackBar, setCustomChatError } from "../../app/slice/chatSlice";
import LogoutModal from "../../components/logoutModal/LogoutModal";
import {getDeviceType} from '../../utils/deviceTypeUtil';
import { Keyboard } from '@capacitor/keyboard';

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

let primcolor=document.documentElement.style.getPropertyValue('--primary-color');
const BpCheckedIcon = styled(BpIcon)({

  backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'#734bd1' ,
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'#734bd1' ,
  },
});

// Inspired by blueprintjs
function BpRadio(props: RadioProps) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};

export default function NewChatMobile() {
  const dispatch=useDispatch()
  const { t } = useTranslation();
  const messageChannelType=useSelector((state:any)=>state.authApi.EmployeeMsgorWhatsAppChannelType)
  const [othersName, setOthersName] = useState("");
  const [assoPrescName, setAssoPrescName] = useState("");
  const [othersPhone, setOthersPhone] = useState("");
  const [hiddenPresList, setHiddenPresList] = useState(true);
  const [assoPresc, setAssoPresc] = useState({ Id: '' });
  const [post, setPost] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [formType, setFormType] = useState(true);
  const [formTemplateVisible, setTemplateVisible] = useState(true);
  
  const [templateValue, setTemplateValue] = useState<any>({});
  const [campaignId,setCampaignId]=useState(0)
  const [templateId,setTemplateId]=useState(0);
  const [templateText,setTemplateText]=useState('')
const [isSMS,setIsSms]=useState(false);

  const [pageSize,setPageSize]=useState(10);
  const [page,setPage]=useState(0);
  const [campaign,setCampaign]=useState([]);
  const [activeCampaign,setActiveCampagign]=useState(1000);//userId
  const [template,setTemplate]=useState<any>([])
  const [othersData,setOthersData]=useState([])
  const [othersDatahidden,setOthersDataHidden]=useState(true)
  const [selectedOthers,setSelecteedOthers]=useState<any>({})
  const debouncedValue = useDebounce<string>(othersName, 350);
  const [assets,setAssets]=useState([]);
  const [disableBrandInput,setDisableBrandInput]=useState(true)
  const [disableCampaignInput,setDisableCampaignInput]=useState(true)
  const [disableWaveInput,setDisableWaveInput]=useState(true)
  const [disableSMSInput,setDisableSMSInput]=useState(true)

  const [phoneError,setPhoneError]=useState(false);
  const [loading,setLoading]=useState(false);
  const [refreshTemplate,setRefreshTemplate]=useState(true)
  const [associated,setAssociated]=useState<any>({})
  const [isForm,setIsForm]=useState(false)
const [formQuestion,SetFromQuestion]=useState("");
const [forms,setForms]=useState([])
const [selectedForm,setSelectForm]=useState<any>({})
  const [optedOut,setOptedOut]=useState(false);
  
  const [formsValues,setFormValues]=useState<any>([])

const refForm=useRef<any>(null);
const [allCampaign,setAllCampaign]=useState<any>([]);
const [allWaves,setAllWaves]=useState<any>([]);

const [activeWave,setActiveWave]=useState<any>(null);
const [activeProduct,setActiveProduct]=useState<any>(null);
const [disableTemplate,setDisableTemplate]=useState<any>(true)
const [modalHeight, setModalHeight] = useState(0);
const [modalBodyHeight, setModalBodyHeight] = useState(0);
const [mobileStyle, setMobileStyle] = useState(false);
const [initialPhone,setInitialPhone]=useState<any>(true);
const [showOnce,setShowOnce]=useState(false);
const [overrideModal,setOverrideModal]=useState(false);
const [formDataGlobal,setFormDataGlobal]=useState<any>(null);
const [templateSelectedValue, setTemplateSelectedValue] = useState(true);
const isReadOnly = true;
  // const {
const navigate = useNavigate();
// const {
  //   data: post,
  //   isFetching,
  //   isSuccess,
  // } = useGetPrescribersQuery({
  //   page: 0,
  //   pageSize: 5,
  //   search: assoPrescName,
  // });
  // const pullTemplateData = (data) => {
  //    console.log(data.length); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  //   // { ? : ''}
  //   if (data.length > 0) {
  //     setTemplateValue(true);
  //   } else {
  //     return null;
  //   }
  // };

  useEffect(() => {
    window.addEventListener('orientationchange', calcHeightWidth);
    return () => {
      window.removeEventListener('orientationchange', calcHeightWidth)
    }
  }, [])

  useEffect(() => {
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      Keyboard.addListener('keyboardDidShow', info => {
        calcHeightWidth();
      });
      Keyboard.addListener('keyboardDidHide', () => {
        calcHeightWidth();
      });
    }
    calcHeightWidth()
    window.addEventListener("resize", (e) => {calcHeightWidth()})
}, []);
  
  useEffect(() => {
    document.documentElement.style.setProperty('--visibility', 'initial');
    document.getElementById("page-name")?.setAttribute('data-content',t('Home'));
    console.log(axios.CancelToken.source());
    // getTemplatesFromCampign();
    calcHeightWidth();
  }, []);
  const calcHeightWidth=()=>{
    let doc:any=document
    let div:any=document.querySelector('#main') 
    let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));

    console.log("gc modal toppad",divpaddingTop);
    console.log("gc modal bottompad",divpaddingbottom);
    console.log("gc modal leftpad",divpaddingleft);
    console.log("gc modal rightpad",divpaddingright);
    console.log("gc modal height",doc.getElementById('MobHeader').offsetHeight);
    console.log("gc modal width",doc.getElementById('headerVal').offsetHeight);
    console.log("gc modal padding",2*parseInt(window.getComputedStyle(doc.getElementsByClassName('modal-body')[0], '').getPropertyValue('padding')));
    console.log("gc modal computed style",window.innerHeight-divpaddingTop-doc.getElementById('MobHeader').offsetHeight-doc.getElementById('headerVal').offsetHeight-doc.getElementById('footer').offsetHeight+(2*parseInt(window.getComputedStyle(doc.getElementsByClassName('modal-body')[0], '').getPropertyValue('padding'))));
    
      let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
      setMobileStyle(true);
      setModalHeight(window.innerHeight-divpaddingTop-divpaddingbottom-doc.getElementById('MobHeader').offsetHeight-doc.getElementById('headerVal').offsetHeight-(2*parseInt(window.getComputedStyle(doc.getElementsByClassName('modal-body')[0], '').getPropertyValue('padding'))));
    //  doc.getElementById('MobHeader').offsetHeight-doc.getElementById('headerVal').offsetHeight-doc.getElementById('footer').offsetHeight
      setModalBodyHeight(window.innerHeight-divpaddingTop-divpaddingbottom-doc.getElementById('MobHeader').offsetHeight-doc.getElementById('headerVal').offsetHeight-doc.getElementById('footer').offsetHeight-(2*parseInt(window.getComputedStyle(doc.getElementsByClassName('modal-body')[0], '').getPropertyValue('padding'))));
  }
   
  }

  const getContactsData = (value) => {
     console.log(template)
    // useGetContactsQuery({
    //         page:this.state.page,
    //         pageSize:this.state.pageSize,
    //         search:this.state.search
    //       })
    // const DATATEMP = useGetContactsQuery({
    //       page:this.state.page,
    //       pageSize:this.state.pageSize,
    //       search:this.state.search
    //     })
    //     console.log(DATATEMP);
    debugger;
    if (value.length >= 2) {
      axios
        .get(
          config.REACT_APP_CONTACTS_API_BASE +
            "/contact?IsPrescriberOnly=false&Page=" +
            0 +
            "&PageSize="+pageSize+"&Search=" +
          encodeURIComponent(value),
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("login"),
            },
          }
        )
        .then((res) => {
          setOthersData(res.data.data.filter((x:any)=>x.Is_Prescriber=="N"))
          setPost(res.data.data.filter((x:any)=>x.Is_Prescriber=="Y"));
          
        });
    } else {
      setOthersDataHidden(true)
      setOthersData([])
      setPost([]);
      setHiddenPresList(true);
    }
  };
  const checkStopTyping = (e) => {
    var timeout = setTimeout(function () {}, 0);
    clearTimeout(timeout); /// clear timeout if user is typing

    timeout = setTimeout(
      function () {
        getContactsData(assoPrescName);
      },
      500 /// Time in milliseconds
    );
  };
  useEffect(() => {
    // Do fetch here...
    // Triggers when "debouncedValue" changes
    setOthersDataHidden(false)
    getContactsData(debouncedValue);
    
    
  }, [debouncedValue])

  const selectedPrescriber=(value, isPrescriber)=>{
    getTemplatesFromCampign(value.Id, isPrescriber)
  }

  const setPrescriber = (x:any) => {
    setAssoPrescName(x.AcctFull_Nm);
    setAssoPresc(x);
    setOthersPhone(getNumberFromItem(x));
    setHiddenPresList(true);
    setDisabled(false);
    // brandInputdisable(true,(x.AcctPh_Nbr.length >0),true)
    brandInputdisable(x.AcctFull_Nm.length >0,(x.AcctPh_Nbr.length >0),true)
    console.log(x)
    setOptedOut(x.Opt_In=="N");
    setTemplateValue('');
    setTemplateText('');
    setTemplateId(0);
    setFormValues([]);
    setAssets([]);
    setTemplateSelectedValue(!templateSelectedValue)
    console.log("Out")
    if(x.AcctPh_Nbr == ""){
      setDisabled(true);
      setDisableSMSInput(true);
      console.log("accc")
    }
    
  };
  const sendMessageForPresOrOthers=(formData)=>{
    debugger
    if(formType){
      //prescriber

     if(getNumberFromItem(assoPresc)!==othersPhone){
      let phn:any=parsePhoneNumber(othersPhone)
      let payload:any={
        AcctPh_Nbr:phn.nationalNumber,
        IntlCallPfx_Nbr:phn.countryCallingCode,
        Id:assoPresc.Id,
      }
      checkNumberExist(payload.AcctPh_Nbr,(res:any)=>{
        if(res){
          setFormDataGlobal(formData)
          setOverrideModal(true);
        }else{
          editContact(payload,(res:any)=>{
          })
          sendToPrescriber(formData)
        }
      })
      
     }else{
      sendToPrescriber(formData)
     }
      
    
     }
     else{
      //others
      if(!selectedOthers.Id){
        let phn:any=parsePhoneNumber(othersPhone);
        let payload={
          AcctPh_Nbr:phn.nationalNumber,
          Prescriber_Id:[associated.Id],
          AcctFull_Nm:othersName,
          IntlCallPfx_Nbr:phn.countryCallingCode
        }
        debugger
        checkNumberExist(payload.AcctPh_Nbr,(res:any)=>{
          if(res){
            debugger
            setFormDataGlobal(formData);
            setOverrideModal(true);
          }else{
            debugger
         addNewContact(payload,(res:any)=>{
          if(res){
      formData.append("recipient_id",res.Id);
      formData.append("recipient_name",othersName);
      formData.append("role_id", '1');
      formData.append('association_id',res.Prescribers.filter(x=>x.Id==associated.Id)[0].Association_Id)
      formData.append("physcian_id",associated.Id);
      sendMessageApi(formData,(resp:any, msgData)=>{
        //console.log(res)
        if(resp){
          dispatch(setChatType("ASSPRS"))
          debugger
          dispatch(setChatDetails(
            {
              chatId:res.Id,
              chatIsPrescriber:false,
              showChat:true,
              chatName:othersName
            }))
            setLoading(false);
            reSetFormInputs(true);
           
            dispatch(setRefreshChatList(true))
            window.location.href = '/Chat';
        }
       })
          }
          else{
            
          }
         })
          }
        })
      }else{
        let phn:any=parsePhoneNumber(othersPhone);
          console.log(phn)
          let payload={
            AcctPh_Nbr:phn.nationalNumber,
            AcctFull_Nm:othersName,
            Prescriber_Id:[associated.Id],
            Id:othersData.map((x:any)=>x.Id),
            IntlCallPfx_Nbr:phn.countryCallingCode
          }  
        formData.append("recipient_id",selectedOthers.Id);
        formData.append("recipient_name",othersName);
        formData.append("role_id", '1');
        formData.append('association_id',associated.Association_Id)
        formData.append("physcian_id",associated.Id);

        if(othersPhone!=getNumberFromItem(selectedOthers))
        {
          checkNumberExist(payload.AcctPh_Nbr,(res:any)=>{
            if(res){
              setFormDataGlobal(formData);
              setOverrideModal(true);
            }else{
              addNewContact(payload,async (cont)=>{
                if(cont)
                {
                  dispatch(setShowSuccessSnackBar(true));
                }
                else
                {
                  dispatch(setShowErrorSnackBar(true));
                }
              })
              sendMessageApi(formData,(res)=>{
                //console.log(res)
                dispatch(setChatType("ASSPRS"))
                dispatch(setChatDetails(
                  {
                  chatId:selectedOthers.Id,
                  chatIsPrescriber:false,
                  showChat:true,
                  chatName:othersName
                  }))
                  setLoading(false);
                  reSetFormInputs(true);
                
                  dispatch(setRefreshChatList(true))
                
              })
          }})
         
        }
        else{
          sendMessageApi(formData,(res)=>{
            //console.log(res)
            dispatch(setChatType("ASSPRS"))
            dispatch(setChatDetails(
              {
              chatId:selectedOthers.Id,
              chatIsPrescriber:false,
              showChat:true,
              chatName:othersName
              }))
              setLoading(false);
              reSetFormInputs(true);
 
              dispatch(setRefreshChatList(true))
            
          })
        }
       
      }
     
    
     }
  }

  const sendToPrescriber=(formData:any)=>{
    formData.append("role_id", '0');
      formData.append("recipient_id",assoPresc.Id );
      formData.append("recipient_name",assoPresc['AcctFull_Nm']);
      formData.append("physcian_id",assoPresc.Id);
      console.log("senddsad ::",templateText);
      sendMessageApi(formData,(res, msgData)=>{
      //console.log(res)
      dispatch(setChatType("PRESCR"))
      dispatch(setChatDetails(
        {
          chatId:assoPresc.Id,
          chatIsPrescriber:true,
          showChat:true,
          chatName:assoPresc['AcctFull_Nm']
        }))
        if(!res) {
          if(msgData) {
            dispatch(setCustomChatErrorSnackBar(true));
            dispatch(setCustomChatError(msgData));
        } 
        }
        setLoading(false);
        reSetFormInputs(true);
        dispatch(setRefreshChatList(true))
        
     })
  }

  async function addContact() {
    // const { data: post, isFetching, isSuccess } =await useAddContactQuery({
    //   AcctPh_Nbr:othersPhone,
    //   Prescriber_Id:x.Id,
    //   AcctFull_Nm:othersName,

    //     })

    //     if(isSuccess){
    //       alert('succee')
    //       ;
    //     }
    //     handleCloseParent()

    let payload = {
      AcctPh_Nbr: othersPhone,
      Prescriber_Id: assoPresc.Id as any,
      AcctFull_Nm: othersName,
      IsPrescriber: false,
    };
    console.log(payload);
    axios
      .post(
        config.REACT_APP_CONTACTS_API_BASE + "/contact",
        {
          AcctPh_Nbr: othersPhone,
          Prescriber_Id: assoPresc.Id as any,
          AcctFull_Nm: othersName,
          IsPrescriber: false,
        },
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("login"),
          },
        }
      )
      .then((res) => {
        // handleCloseParent(true);
      })
      .catch((err) => {
        // handleCloseParent(false);
      });
  }
  const sendMessage=()=>{
    // let data=messageData[0];
    // console.log(contactData);
    
    setLoading(true);

let formData = new FormData();

debugger
formData.append("recipient_phone",othersPhone);

formData.append("Campaign_ID", '1000')//templateValue.campaginId.toString());


formData.append('wave_id',templateValue.mainWaveId)//templateValue.waveId.toString())
formData.append('product_id',templateValue.productId)//templateValue.productId.toString())

if (!isSMS) {
  formData.append("channel", "SMS");
 
 } else {
  formData.append("channel", "whatsa");
 }
 debugger


 if(isForm){
  let fdata:any=new FormData(refForm.current)
  let details:any=[];
  for (const [key, value] of fdata) {
    details.push({"idOfFieldToAskFromEmp": JSON.parse(key),"valueOfFieldToAskFromEmp": value})
  }
 
  let formPay={  "formId" : selectedForm.FormId,
  "fieldDetails": details,
  "recipientId" : assoPresc.Id,
  "recipientType" :formType?"PRESCR":'ASSPRS'

}
  console.log(JSON.stringify(formPay))
  axios.post(config.REACT_APP_FORMS_API_BASE+'/formRequest',formPay).then((res:any)=>{
    console.log(res.data)
    let temptest=templateText.toString();
    if(temptest.indexOf('shorturl')>=0)
      formData.append("template",templateText.toString().replace('[shorturl_1]',res.data.FormUrl));
    else
    formData.append("template",templateText+" "+res.data.FormUrl);
    formData.append('urls',res.data.FormUrl)
    sendMessageForPresOrOthers(formData);
  })
  .catch(err=>{
    setLoading(false)
  })
}
else{
  formData.append("template_id",templateId.toString());
  formData.append("template",templateText.toString());
  sendMessageForPresOrOthers(formData);
}




//for prescriber


// formData.append("recipient_name", data['Sender Name']);
// formData.append("recipient_phone", data.recipient_phone);
//  formData.append("physcian_id", data.physcian_id);
//  formData.append("role_id", data.type);
//  formData.append("template", data.template);
//  formData.append("Campaign_ID", 1000);
//  formData.append("template_id");
//  formData.append('wave_id',data.)

debugger

// formData.append("file", this.files);
//  for (var i = 0; i < files.length; i++) {
//    let file = files[i];
//    formData.append("file", file);
//  }


   // axios.post(config.REACT_APP_MESSAGE_API_BASE+"/message",formData,{
    //   axios.post("http://localhost:8000/api/message",formData,{
    //     params:{"Authorization":"Bearer "+sessionStorage.getItem('login')}
    // }).then((res)=>{
    //   console.log(res)
    //   setLoading(false);
    //   reSetFormInputs(true);
    //   handleCloseParent(true)
    // }).catch(res=>setLoading(false))
  }
  const getTemplatesFromCampign = (id, isPrescriber) => {
    getCampaignsData({page:0,pageSize:10, id: id, isPrescriber: isPrescriber},(res)=>{
     if (res.length>0) {
   
             let tempAssets: any = [];
             let campaigns:any=[];
             let waves:any=[];
             let products:any=[];
 


res.map((x:any) => {

  campaigns.push(x);  
  x.type = "Campaign";
  x.idName = ["type", "CampaignName", "CamapignId"];
  x.labelName = "CampaignName";
  x.childName = "WaveDetails";
  x.WaveDetails.map((y: any) => {
    waves.push(y);
    y.type = "Wave";
    y.idName = ["type", "WaveName", "WaveId"];
    y.labelName = "WaveName";
    y.childName = "ProductDetails";
    //y.CampaignWave_Nm=y.WaveDetails?y.WaveDetails.Wave_Nm:"";
    y.ProductDetails.map((z:any)=>{
      z.waveId=y.WaveId;
      products.push(z)
      z.type = "Product";
    z.idName = ["type", "ProductName", "ProductId"];
    z.labelName = "ProductName";
    z.childName = "TemplateDetails";
    z.TemplateDetails= z.TemplateDetails.sort(function(a:any,b:any){return a.SortOrder_Nbr - b.SortOrder_Nbr})
      z.TemplateDetails.map((p:any)=>{
        p.type = "Template";
        p.productId = z.ProductId;
        p.mainWaveId=(p.AssetDetails.length==0)?p.CampaignWaveId:p.AssetDetails[0].CampaignWaveId
        if(p.FormId){
          p.TemplateName=p.FormName
        }
    p.idName = ["type", "TemplateName", "TemplateId"];
    p.labelName = "TemplateName";
    p.childName = "AssetDetails";
        tempAssets=tempAssets.concat(p.AssetDetails)
        p.AssetDetails.map((o:any)=>{
          o.mainItem=true
      o.type= "Asset"
      o.idName= ["type", "AssetFile_Nm", "AssetId"]
      o.labelName="AssetFile_Nm"
          return o;
        })
        return p;

        //tempAssets.push(y.campaignWaveAsset);
      })
      return z;
    })
    return y;
  });
  return x;
});
debugger;
            setAllCampaign(campaigns)
           // setDisableBrandInput(true);
            let activeC:any=window.localStorage.getItem('activeCampaign')
            if(activeC){
              let index=campaigns.findIndex(x=>x.CamapignId==JSON.parse(activeC).CamapignId)
              if(index>=0){
                activeC=JSON.parse(activeC);
                setActiveCampagign(campaigns[index])
              }
              else{
                activeC=campaigns[0];
                setActiveCampagign(campaigns[0])
              } 
              
            }else{
              activeC=campaigns[0];
              setActiveCampagign(campaigns[0])
            } 

            
           debugger
            let tempWaves=activeC.WaveDetails?activeC.WaveDetails:[];
            setAllWaves(tempWaves)
            
            let activeW:any=window.localStorage.getItem('activeWave')
            debugger
            if(activeW){
              let index=tempWaves.findIndex(x=>x.WaveId==JSON.parse(activeW).WaveId)
              if(index>=0){
                activeW=JSON.parse(activeW);
                setActiveWave(tempWaves[index])
              }
              else{
                activeW=tempWaves[0];
                setActiveWave(tempWaves[0])
              } 
              
            }else{
              activeW=tempWaves[0];
              setActiveWave(tempWaves[0])
            } 


            // let p:any=window.localStorage.getItem('activeWave')
            // setActiveWave(p?JSON.parse(p):null)
            let tempProducts=activeW.ProductDetails?activeW.ProductDetails:[]
            debugger
            setCampaign(tempProducts)//campagins is used as products
            let activep:any=window.localStorage.getItem('activeProduct')

            if(activep){
              let index=tempProducts.findIndex(x=>x.ProductId==JSON.parse(activep).ProductId)
              if(index>=0){
                activep=JSON.parse(activep);
                setActiveProduct(tempProducts[index])
              }
              else{
                activep=tempProducts[0];
                setActiveProduct(tempProducts[0])
              } 
              
            }else{
              activep=tempProducts[0];
              setActiveProduct(tempProducts[0])
            } 


            // let o=window.localStorage.getItem('activeProduct')
            // setActiveProduct(o?JSON.parse(o):null)
            let tempTemplates=activep.TemplateDetails?activep.TemplateDetails:[]
            setTemplate(tempTemplates);
            
           // getFormsData()
          // setTemplate(tempAssets);
          // setAlllProducts(products);
            // setActiveProducts(products)
 
             // setAlllWaves(waves);
             // setActiveWaves(waves);
             // setAssets(tempAssets);
    }
   }
    )
 
  
   };
  const reSetFormInputs = (e)=>{
    // console.log(e)
    setInitialPhone(true)
    setFormType(e)
    // if(e === true){
      // setOthersPhone("");
      // setTemplateText("")
      // setAssoPrescName("");
    // }else{
      setTemplateId(0)
      setAssoPrescName("");
    setAssoPresc({Id: ''});
    setHiddenPresList(true);
    setDisabled(true);
    setOthersPhone("");
    setDisableBrandInput(true)
    setDisableCampaignInput(true)
    setDisableWaveInput(true)
    setDisableSMSInput(true)
    // setTemplate([]);
    setTemplateText("");
    setOthersName("");
  //  setAssoPrescName("");
   setAssets([]);
   setAssociated({})
   setOptedOut(false);
    // }
   }
  // const getTemplatesFromCampign=(activeCampaign:any)=>{
  //   axios.get(config.REACT_APP_CAMPAIGN_API_BASE+"/campaign/"+activeCampaign+"?Page="+page+"&PageSize="+pageSize,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then((res)=>{
  //     //setAssets(res.data.data);
  //     let tempAssets:any=[];
  //     console.log(res.data.data)
  //     setCampaign(res.data.data)
  //     res.data.data.map(x=>{
  //       x.refCampaignWaves.map((y:any)=>{
  //        tempAssets.push(y.campaignWaveTemplate);
  //        return y;
  //       });
  //       return x;
  //     })
  //     setTemplateId(tempAssets[0].id)
  //     setTemplateText(tempAssets[0].templateVal)
  //     setTemplate(tempAssets);
  //   })
  // }

  const setPrescriberName =(e) =>{
    // if(e.length > 0){
    //  setDisabled(false)
    // }
    // else setDisabled(true)
    if(assoPrescName.length <=0){
      setDisabled( true)
    } 
    else {
      setDisabled(false)
    }
  }
  const brandInputdisable = (name,phone,asso_pres) =>{
  debugger;
    // console.log(assoPrescName === "" && othersPhone === "", assoPrescName, othersPhone)
    if(formType){
      if(name && phone && asso_pres){
        debugger
        setDisableBrandInput(false)
        setDisableCampaignInput(false)
        setDisableWaveInput(false)
        setDisableSMSInput(false)
      } else {
        debugger
        setDisableBrandInput(true)
        setDisableCampaignInput(true)
        setDisableWaveInput(true)
        setDisableSMSInput(true)
      } 
    }
    else{
      setDisableBrandInput(false)
      setDisableCampaignInput(false)
      setDisableWaveInput(false)
      setDisableSMSInput(false)
    }
       
  }
  const getNumberFromItem=(item)=>{
    debugger
    if(item.AcctPh_Nbr){
      if(item.AcctPh_Nbr.indexOf("+")>=0){
        return item.AcctPh_Nbr
      }else
      return '+'+item.IntlCallPfx_Nbr+item.AcctPh_Nbr
    }
    else return ""
  }
const setPhoneNumber =(e) =>{
  debugger;
  setInitialPhone(false)
  if(e){
    setOthersPhone(e);
    brandInputdisable((assoPrescName.length > 0),(e.length >0),(assoPrescName.length > 0));
  } else {
    setOthersPhone("");
    brandInputdisable((assoPrescName.length > 0),false,(assoPrescName.length > 0));
  }
  // debugger
  
}
const selectbrand=(e)=>{
  debugger;
  console.log('inside brand')
  window.localStorage.setItem('activeProduct',JSON.stringify(e))
  setTemplate(e.TemplateDetails?e.TemplateDetails:[])
  setDisableTemplate(false)
  // if(e.TemplateDetails.length==1){
  //   setDisableTemplate(false)
  // }
  // else{
  //   setDisableTemplate(true)
  // }
  setActiveProduct(e);
  setTemplateText("");
  setTemplateId(0)
  setAssets([]);
  setIsForm(false)
  setDisableSMSInput(false);
  // if(e)
  // {
  //   setTemplateVisible(true);
  // }
  //  getFormsData(1001)
}
const selectWave=(e)=>{
  // setDisableTemplate(true)
  window.localStorage.setItem('activeWave',JSON.stringify(e))
  //setActiveWave(e);
  setActiveWave(e)
  setActiveProduct(null)
  
  setCampaign(e.ProductDetails?e.ProductDetails:[])
  if(e.ProductDetails.length==1){
    selectbrand(e.ProductDetails[0])
  }
  setTemplateText("");
  setTemplateId(0)
  setAssets([]);
  setIsForm(false)
  if(disableWaveInput==false)
  {
    if(allWaves.length==1){
      setDisableSMSInput(false);
    }
    else
    {
      setDisableBrandInput(false);
      setDisableSMSInput(true);
    }
  
  }
  else
  {
    setDisableBrandInput(true);
    setDisableSMSInput(false);
  }

}
const selectCampaign=(e)=>{
  setDisableTemplate(true)
  window.localStorage.setItem('activeCampaign',JSON.stringify(e))
  //setActiveCampagign(e)
  setActiveWave(null) 
  setActiveProduct(null)
  setAllWaves(e.WaveDetails?e.WaveDetails:[])
  if(e.WaveDetails.length==1){
    selectWave(e.WaveDetails[0])
  }
  setTemplateText("");
  if(disableCampaignInput==false)
  {
    setDisableWaveInput(false);
    setDisableSMSInput(true);
    setDisableBrandInput(true);
  }
  else
  {
    setDisableWaveInput(true);
    setDisableSMSInput(false);
    setDisableBrandInput(false);
  }
  setTemplateId(0)
  setAssets([]);
  setIsForm(false)
}

const setOthersFromList=(x)=>{
  setShowOnce(false);
  setOptedOut(x.Opt_In=="N");                            
  setSelecteedOthers(x);
    setOthersName(x.AcctFull_Nm);
    //setOthersPhone(x.AcctPh_Nbr);
    setOthersDataHidden(true);
    if(x.Prescribers){
      debugger
      if(x.Prescribers.length==1){
         
         setAssociated(x.Prescribers[0]);
         setPrescriberName(x.Prescribers[0].AcctFull_Nm);
         setAssoPrescName(x.Prescribers[0].AcctFull_Nm);
         setDisableTemplate(x.AcctPh_Nbr.length<=0)
      }
      else{
        brandInputdisable(true,(x.AcctPh_Nbr.length >0), x.Prescribers.length >0)
      }
      // setPrescriber(x.Prescribers);
    }
    setOthersPhone(getNumberFromItem(x));
    
    
}
  const getHeight=()=>{
    let viewportHeight = window.innerHeight;
    let doc:any=document;
    if(doc.getElementsByClassName('new_chat_body')[0])
    return (viewportHeight - doc.getElementsByClassName('new_chat_body')[0].offsetTop - 50) ;
    else return "100%"
  }

  const overrideFunction=(e)=>{
    let formData:any=formDataGlobal;
    if(e){
      if(formType){
        let phn:any=parsePhoneNumber(othersPhone)
        let payload:any={
          AcctPh_Nbr:phn.nationalNumber,
          IntlCallPfx_Nbr:phn.countryCallingCode,
          Id:assoPresc.Id,
        }
        editContact(payload,(res:any)=>{})
        sendToPrescriber(formData)
      }
      else{
        if(!selectedOthers.Id){
          let phn:any=parsePhoneNumber(othersPhone);
          let payload={
            AcctPh_Nbr:phn.nationalNumber,
            Prescriber_Id:[associated.Id],
            AcctFull_Nm:othersName,
            IntlCallPfx_Nbr:phn.countryCallingCode
          }
          
           addNewContact(payload,(res:any)=>{
            if(res){
        formData.append("recipient_id",res.Id);
        formData.append("recipient_name",othersName);
        formData.append("role_id", '1');
        formData.append('association_id',res.Prescribers.filter(x=>x.Id==associated.Id)[0].Association_Id)
        formData.append("physcian_id",associated.Id);
        sendMessageApi(formData,(resp:any)=>{
          //console.log(res)
          if(resp){
            dispatch(setChatType("ASSPRS"))
            debugger
            dispatch(setChatDetails(
              {
                chatId:res.Id,
                chatIsPrescriber:false,
                showChat:true,
                chatName:othersName
              }))
              setLoading(false);
              reSetFormInputs(true);
              
              dispatch(setRefreshChatList(true))
           
          }
         })
            }
            else{
              
            }
           })
        }else{
          let phn:any=parsePhoneNumber(othersPhone);
          console.log(phn)
          let payload={
            AcctPh_Nbr:phn.nationalNumber,
            AcctFull_Nm:othersName,
            Prescriber_Id:[associated.Id],
            Id:othersData.map((x:any)=>x.Id),
            IntlCallPfx_Nbr:phn.countryCallingCode
          } 
          addNewContact(payload,async (cont)=>{
            if(cont)
            {
              dispatch(setShowSuccessSnackBar(true));
            }
            else
            {
              dispatch(setShowErrorSnackBar(true));
            }
          })
          sendMessageApi(formData,(res)=>{
            //console.log(res)
            dispatch(setChatType("ASSPRS"))
            dispatch(setChatDetails(
              {
              chatId:selectedOthers.Id,
              chatIsPrescriber:false,
              showChat:true,
              chatName:othersName
              }))
              setLoading(false);
              reSetFormInputs(true);

              dispatch(setRefreshChatList(true))
           
          })

        }
      }
    }
    else{
      setLoading(false)
      setDisabled(false)
    }
  }
  return (
    <div className="new-chat-wrapper" style={mobileStyle?{height:modalHeight}:{}}>
            <div className="modal-body new_chat_body" style={mobileStyle?{height:modalBodyHeight}:{}}>
              <form onSubmit={(e)=>e.preventDefault()}>
              <div className="selecttype-radio-btns">
                <FormControl className="width100per">
                  <div className="newChatModalBody">
                  <FormLabel id="demo-customized-radios">{t('Select Type')}</FormLabel>
                  {
                        messageChannelType === true?
                  <div className='headerright' >
                    <label className="autopilot-switch font-sm me-3">
                        <input
                        checked={isSMS}
                            onChange={(e)=>{setIsSms(e.target.checked)}}
                            type="checkbox"
                            className="font-sm"
                            id="msg-wtsappChechedId"
                        />
                        <span id="msg-wtsapptoggle" className="b-slider b-round"></span>
                    </label>
                </div>:null}
                </div>
                  <RadioGroup
                    row
                    defaultValue="prescriber"
                    aria-labelledby="demo-customized-radios"
                    name="customized-radios"
                    onClick={(e: any) => {
                      e.target.value === "prescriber"
                        && reSetFormInputs(true)
                    }}
                  >
                    <FormControlLabel
                      value="prescriber"
                      control={<BpRadio />}
                      label={t('Prescriber')}
                    />
                    <FormControlLabel
                      value="others"
                      control={<BpRadio />}
                      label={t('Others')}
                      disabled = {true}
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="form-fields-div new-chat-prescriber">
                {formType === true ? (
                  // <TextField
                  //   className="roundborder"
                  //   onChange={(e) => {
                  //     setOthersName(e.target.value);
                  //   }}
                  //   value={othersName}
                  //   label="Prescriber Name"
                  //   placeholder="Please enter the prescriber name"
                  //   color="secondary"
                  //   focused
                  // />
                  <AssociatedPresList selectedPrescriber={selectedPrescriber} clearDataOnSelect={false} placeholder={t('Search for a Prescriber')} label={t('Prescriber Name')} styleClass="inputDisable"  onChangeInputValue={(e:any)=>{
                    if(e == ''){
                      setDisabled(true);
                      setDisableSMSInput(true);
                      setTemplateValue('');
                      setTemplateText('');
                      setTemplateId(0);
                      setFormValues([]);
                      setAssets([]);
                      setTemplateSelectedValue(!templateSelectedValue)
                    }
                    setPrescriberName(e); setDisabled(true)}}  setAssoPresc={(e:any)=>{setPrescriber(e); setDisabled(false);}} />
                ) : (
                  <div className="NewChatModalOthersName">

                  
                  <TextField
                    className="roundborder inputEnable"
                    onChange={(e) => {
                      setShowOnce(true);
                      if(e.target.value.length==0){
                        setDisabled(true);
                        setSelecteedOthers({});
                        setOthersPhone("");
                      setAssoPrescName("");
                      // reSetFormInputs(false)
                        brandInputdisable(othersName.length > 0,othersPhone.length > 0,assoPrescName.length > 0)
                       
                      }
                      else if(e.target.value.length > 0){
                        if(assoPrescName.length > 0 && othersPhone.length > 0) {
                          setDisabled(false);
                        }
                        brandInputdisable(othersName.length > 0,othersPhone.length > 0,assoPrescName.length > 0)
                      }
                      setOthersName(e.target.value);
                      
                    }}
                    value={othersName}
                    label="Recipient Name"
                    placeholder={t("Enter the recipient's name")}
                    color="secondary"
                    focused
                    // disabled
                  />
                  {showOnce && othersData.length > 0?
                  <Paper   hidden={othersDatahidden}>
                      

                      <div id="presList" >
  
                        { othersData.length > 0 && (
                           othersData.map((x: any) => <div key={x.Id} onClick={()=>{setOthersFromList(x)}}>{x.AcctFull_Nm}</div>)
                        )
                        }
                      </div>
                    </Paper>:null}
                  {/* <div className="othesNameDiv" hidden={othersDatahidden}>
                    <ul>
                      {
                        othersData.map((x:any)=>(
                          <li key={x.Id} onClick={()=>{
                            setOthersFromList(x);
                            
                          }}>{x.AcctFull_Nm}</li>
                        ))
                      }
                      
                    </ul>
                  </div> */}
                  </div>

                )}
                {
                  optedOut?<div className="optedOutModalText">
                    {t("Recipient Opted Out")}
                  </div>:null
                }
                <CountryDropdown errorFunc={(e)=>{console.log(e);
                  setDisabled(e);
                  setPhoneError(e);
                  
                  debugger
                  if(e)
                  {
                    setDisableTemplate(assoPrescName.length > 0 && true)
                    brandInputdisable((assoPrescName.length > 0),false,assoPrescName.length > 0)
                  }
                  else{
                    debugger;
                    if(formType && assoPrescName.length<=0)
                    {
                    setDisabled(true)
                    }
                    else if(!formType && (assoPrescName.length <=0 || othersName.length <= 0 || othersPhone.length <= 0))
                    {
                    setDisabled(true)
                    }
                    setDisableTemplate(assoPrescName.length > 0 && false)
                  }
                  
                  }}isReadOnly = {isReadOnly} disableColored={disableBrandInput} valueNumber={othersPhone} onChangeNumber={(e:any)=>{setPhoneNumber(e)}}/>
                {/* <TextField
                  label="Phone Number"
                  onChange={(e) => {
                    setOthersPhone(e.target.value);
                  }}
                  value={othersPhone}
                  placeholder="Please enter the phone number"
                  color="secondary"
                  focused
                /> */}
                {/* Associate perscriber */}
                {formType === false ? (
                  <div className="asso-prescriber-div createContactAssociate">
                   <div>
                   {
                      selectedOthers.Id?
                      <div>
                        {selectedOthers.Prescribers.length>1?
                      <DropdownInupt disableColored={false} keepPlaceHolderAtStart={selectedOthers.Prescribers.length>1} placeHolder={t("Select a Prescriber")} label={t("Associated Prescriber")} data={selectedOthers.Prescribers} id="" name="AcctFull_Nm"  func={(e)=>{setAssoPrescName(e.AcctFull_Nm);setAssociated(e); brandInputdisable(othersName.length > 0,othersPhone.length > 0,true);setDisableTemplate(false); (e? setDisabled(false): setDisabled(true))}}/>
                    :
                    <div>
                         <AssociatedPresList SelectedValue={selectedOthers.Prescribers[0].AcctFull_Nm} clearDataOnSelect={false} placeholder={t("Search for an Associated Prescriber")} label={t("Associated Prescriber")} styleClass="inputEnable"  onChangeInputValue={(e:any)=>{setPrescriberName(e);if(e==''){setDisabled(true)}}} setAssoPresc={(e:any)=>{ brandInputdisable(othersName.length > 0,othersPhone.length > 0,true); setAssoPrescName(e.AcctFull_Nm);setAssociated(e);setDisableTemplate(false); (e? setDisabled(false): setDisabled(true)) }} />
                          {
                            // optedOut?<div>
                            //   {t("Recipient Opted Out")}
                            // </div>:null
                          } 
                      </div>
                    }
                      </div>
                      :
                      <div>
                         <AssociatedPresList clearDataOnSelect={true} placeholder={t("Search for an Associated Prescriber")} label={t("Associated Prescriber")} styleClass="inputEnable"  onChangeInputValue={(e:any)=>{setPrescriberName(e);setDisabled(true);}} setAssoPresc={(e:any)=>{if(e)setDisabled(false); brandInputdisable(othersName.length > 0,othersPhone.length > 0,true); setAssoPrescName(e.AcctFull_Nm);setAssociated(e);setDisableTemplate(false); if(othersName.length<=0){ setDisabled(true) } }} />
                          {/*{
                            optedOut?<div>
                              {t("Recipient Opted Out")}
                            </div>:null
                          } */}
                      </div>
                    }
                   </div>
                    
                    
                  
                  </div>
                ) : null}
                 {
                  allCampaign.length>1?
                  <div>
                  <DropdownInupt activeValue={activeCampaign} disableColored={disableCampaignInput} keepPlaceHolderAtStart={false} placeHolder={t("Select a campaign")} label="Campaigns" data={allCampaign} id="" name="CampaignName"  func={(e)=>{selectCampaign(e); setDisableSMSInput(false)}}/>
                </div>
                :null
                }
                {
                  allWaves.length>1?
                  <div>
                  <DropdownInupt activeValue={activeWave} disableColored={disableWaveInput} keepPlaceHolderAtStart={allWaves.length>1} placeHolder={t("Select a wave")} label="Waves" data={allWaves} id="" name="WaveName"  func={(e)=>{selectWave(e);setDisableSMSInput(false);}}/>
                </div>
                :null
                }
                {
                campaign.length>1?
                <div>
                  <DropdownInupt activeValue={activeProduct}  disableColored={disableBrandInput} keepPlaceHolderAtStart={campaign.length>1} placeHolder={t("Select a brand")} label="Brand" data={campaign} id="" name="ProductName"  func={(e)=>{selectbrand(e); setDisableSMSInput(false);}}/>
                </div>:null
               }

               
                <div>
                    <DropdownInupt
                     notdisabledAfter={true}
                    //refreshData={refreshTemplate}
                    templateSelectedValue={templateSelectedValue}
                    key={formType?1:0}
                    label="SMS Template"
                    data={template}
                    keepPlaceHolderAtStart={true} 
                    placeHolder={t("Select a Template")}
                    name="TemplateName"
                    disableColored={disableSMSInput}
                    func={(e)=>{setIsForm(e.FormId!==null);setTemplateText(e.TemplateBody);setTemplateValue(e);setTemplateId(e.TemplateId);
                      setDisabled(false);
                      if(formType && assoPrescName.length<=0)
                    {
                    setDisabled(true)
                    }
                    else if(!formType && (assoPrescName.length <=0 || othersName.length <= 0 || othersPhone.length <= 0))
                    {
                    setDisabled(true)
                    }
                    setAssets(e.AssetDetails?e.AssetDetails:[]);setSelectForm(e);setFormValues(e.FieldDetails)}}
                  />
                  {templateText.length > 0?
                  <div className="newchat-floating-form" style={{display:"block"}}>
                  <textarea name="sms-textarea" id="newchat_sms" value={templateText} />
                  <p>
                    <em>Characters : {templateText.length}</em>
                  </p>
                </div>: null}

                  
                {
                  isForm?
                  
                  <div className="template_body_form">
                    <form ref={refForm}>
                    {
                      formsValues.map((x:any,i:any)=>(
                        <>
                        <p className="para">{x.FieldName}</p>
                        <textarea name={x.FieldId} id={x.FieldId}  ></textarea>
                        </>
                      ))
                    }
                 </form>
                  </div>
                       
                 :null
                }
               
                  {

assets.length>0?
<div
className="template_assets_form"
>
<ul>
  {
    assets.map((x:any)=>(
      <li> <a href={x.AssetFile_Url}>{x.Asset_Nm}</a> </li>
    ))
  }
 
</ul>
<label htmlFor="">{t('Assets')}</label>
</div>
:null
}
                </div>

                {/* <br /> */}
                <GenericButton label={t("Send")} 
               disable={disabled || templateId==0 ||optedOut} 
                 loading={loading} onClick={() => {
                    sendMessage();
                  }} />
              </div>
              </form>
            </div>
    <LogoutModal
      show={overrideModal}
      yorn={overrideFunction}
      modalText={t('This number has already been assigned. Do you want to override the number?')}
      
      handleCloseParent={(e)=>{
        setOverrideModal(false)
      }} />
    </div>
  );
}

