import axios from "axios";
import config  from '../../env.json';
function getAuthApi(params,callback){
  let {authToken}=params;
  try {
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      axios.get(config.REACT_APP_AUTH_API_BASE+"/auth/?EndpointType=SMS")
       .then(res=>{
         callback(res.data);
      }).catch(()=>{
       callback(null)
     })
    } else {
       axios.get(config.REACT_APP_AUTH_API_BASE+"/auth/?EndpointType=SMS",{ headers: {"Authorization" :authToken} })
       .then(res=>{
         callback(res.data);
       }).catch(()=>{
       callback(null)
     })
    }
  } catch (error) {
       callback(null)
  }

}


export {getAuthApi}

